















































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import PICKUP_REMOVE from '@/graphql/mutations/PickupRemove.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { GraphQLError } from 'graphql';
import { PickupRemoveMutationVariables } from '@/types/schema';

export default Vue.extend({
  name: 'DriverRoutePickupRemove',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        reason: '',
        stickReading: '' as string | number,
        weight: '' as string | number,
      },
    };
  },
  computed: {
    reasons() {
      return ['Temperature', 'Odor / Smell', 'Debris', 'Other'];
    },
  },
  validations() {
    return {
      form: {
        reason: { required },
        stickReading: { required, decimal },
        weight: { required, decimal },
      },
    };
  },
  methods: {
    validationMessages,
    async removePickup() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: PICKUP_REMOVE,
          variables: {
            input: {
              pickupId: this.$route.params.pickupId,
              reason: this.form.reason,
              stickReading: this.form.stickReading,
              weight: this.form.weight,
            },
          } as PickupRemoveMutationVariables,
        });
        this.$router.replace({
          name: 'driver-route',
          params: { id: this.$route.params.id },
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
});
